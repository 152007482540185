import React, {useState, useRef, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import apiConfig from '../../config/apiConfig';
import HeaderTwo from '../../components/header/HeaderTwo';
import Breadcrumb from './Breadcrumb';
import Footer from '../../components/footer/Footer';

function OrderTest() {
    const params = useParams();
	const itemId = params.itemId; 
    const originalData = {
    'itemId': itemId
    }
    const [data, setData] = useState(originalData);
    const [resultMessage, setResultMessage] = useState();
    const formRef = useRef();

    useEffect(() => {
            
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '1190099375579337',
                xfbml      : true,
                version    : 'v20.0'
            });
            
            window.FB.AppEvents.logPageView();   
            
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    
      
    }, [])
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(data?.name && data?.email && data?.phoneNumber && data?.quantity && parseInt(data?.quantity)>0 && data?.address) {
            const response = await fetch(apiConfig.orderApi, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
    
            const result = await response.json();
            if(result.data) {
                setData(originalData);
                setResultMessage({ className: "bg-warning",  message: "Order Success"});
                formRef.current.reset();
                
            }else{
                alert("Something went wrong");
            }
        }else{
            setResultMessage({ className: "bg-danger",  message: "Please fill all the information correctly"});
        }
        
    }
    return (
        <>
            <HeaderTwo/>
            <main>
                <Breadcrumb/>
                <section className="contact-area pt-120 pb-120 event-bg" id="orderform">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="section-title text-center mb-70">
                                    <h2 className="text-dark">Order Form</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-6 pl-45">
                                <div className="contact-form">
                                    <form ref={formRef}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" onChange={(e) => setData({...data, name: e.target.value})} placeholder="Your Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" onChange = {(e) => setData({...data, email: e.target.value})} placeholder="Your Mail" />
                                            </div>
                                            <div className="col-md-12">
                                                <input type="text" onChange = {(e) => setData({...data, phoneNumber: e.target.value})} placeholder="Your Phone Number" />
                                            </div>
                                            <div className="col-md-12">
                                                <input type="number" onChange = {(e) => setData({...data, quantity: e.target.value})} placeholder="Quantity"/>
                                            </div>
                                            <div className="col-md-12">
                                            <textarea placeholder="Your Address"  onChange = {(e) => setData({...data, address: e.target.value})} defaultValue={""} />
                                            </div>
                                        </div>
                                        <button onClick={handleSubmit}>Order</button>
                                        <div className="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="false"></div>
                                        {
                                            resultMessage && (
                                                <p className={`mt-5 text-white p-3 ${resultMessage.className}`}>{resultMessage.message}</p>
                                            )
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer/>
    </>
    )
}

export default OrderTest